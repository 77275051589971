.mb-logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  height: 64px;

  img {
    position: relative;
    width: 79%;
    top: 3px;
  }
}
